import { useEffect } from "react";

const useExternalScript = (src) => {
  useEffect(() => {
    // Check if the script is already present
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (existingScript) return;

    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [src]);
};

export default useExternalScript;
