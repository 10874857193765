import React from "react";
import useExternalScript from "../../hooks/useExternalScript";

export const BirdEyeRectWidget = () => {
  // Using the custom hook to load the script
  useExternalScript(
    "https://birdeye.com/embed/v7/170973506209180/7/9876543214715183"
  );

  return <div id="bf-revz-widget-9876543214715183"></div>;
};

export const BirdEyeRotatingWidget = () => {
  // Using the custom hook to load the script
  useExternalScript(
    "https://birdeye.com/embed/v7/170973506209180/3/9876543213315183"
  );

  return <div id="bf-revz-widget-9876543213315183"></div>;
};

export const BirdEyeReviewGrid = () => {
  // Using the custom hook to load the script
  useExternalScript(
    "https://birdeye.com/embed/v7/170973506209180/11/987654321301115183"
  );

  return <div id="bf-revz-widget-987654321301115183"></div>;
};

export default BirdEyeRectWidget;
