import * as React from "react";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import { Carousel } from "@mantine/carousel";
import Section from "../components/layout/section";
import "./card.css";
import facepaint from "facepaint";
import { StaticImage } from "gatsby-plugin-image";
import "./index.css";
import Heading from "../components/text/myheading";
import { Quote } from "../components/text/quote";
import {
  BirdEyeRectWidget,
  BirdEyeRotatingWidget,
  BirdEyeReviewGrid,
} from "../components/social/birdeye";

const breakpoints = [576, 768, 992, 1200, 1300];

const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const SectionContainer = styled.div((props) => {
  return mq({
    display: "flex",
    maxWidth: "1300px",
    margin: ["2em 3em", "2em auto"],
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "0.5rem 0.5rem 0 0",
    overflow: "hidden",
  });
});

const headingMedium = {
  fontWeight: "800",
  fontFamily: "Quicksand,Helvetica,Arial,sans-serif",
  marginBottom: "2rem",
  fontSize: "2rem",
  textAlign: "center",
};

const paragraph = {
  fontFamily: "Roboto,sans-serif",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "32px",
  margin: "1em auto",
  maxWidth: "800px",
  textAlign: "center",
};

const OverlayLeft = styled.div((props) => {
  return mq({
    position: "absolute",
    zIndex: 1,
    width: [0, 0, 0, "15%", "20%"],
    left: 0,
    height: "inherit",
    display: "display",
    background:
      "linear-gradient(270deg, rgba(2,0,36,0) 0%, rgba(3,20,38,0.852000175070028) 26%, rgb(5 10 37 / 94%) 54%, rgb(3 4 38) 78%)",
  });
});

const OverlayRight = styled.div((props) => {
  return mq({
    position: "absolute",
    zIndex: 1,
    width: ["50%", "35%"],
    right: 0,
    height: "inherit",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    background:
      "linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(3,20,38,0.852000175070028) 26%, rgb(5 10 37 / 94%) 54%, rgb(3 4 38) 78%)",
  });
});

const HeadingPrimary = styled.h1((props) => {
  return mq({
    width: "100%",
    color: "white",
    paddingRight: "0.5em",
    fontSize: ["24px", "32px", "32px", "40px"],
    textAlign: "left",
    fontWeight: 800,
    marginLeft: ["1em", "1em", "1em", "0em"],
    fontFamily: "Inter, sans-serif",
  });
});

const FlooringTypes = styled.div((props) => {
  return mq({
    display: "flex",
    flexDirection: ["column", "row"],
    alignItems: "center",
    justifyContent: "center",
    margin: "2em auto",
    columnGap: ["50px", "10px"],
    rowGap: ["50px", "10px"],
  });
});

const FlooringIcon = {
  alignSelf: "end",
  justifySelf: "end",
  display: "block",
};

const AngiesList = {
  alignSelf: "center",
  justifySelf: "center",
  display: "block",
};

const FlooringIconTitle = {
  marginTop: "1em",
  display: "block",
  fontWeight: "800",
  height: "100px",
  alignSelf: "center",
  justifySelf: "center",
};

const ReviewContainer = styled.div((props) => {
  return mq({
    margin: ["2em 1em 6em", "2em 0 6em"],
    display: "flex",
    width: "100%",
    flexDirection: ["column", "column", "column", "row"],
    justifyContent: "space-between",
  });
});

const MiddleReview = styled.div((props) => {
  return mq({
    margin: ["2em 0 2em","3em 0 3em","3em 0 3em", "0"],
    width: ['100%','100%','100%',"50%"],
  });
});

const QuoteContainer = styled.div((props) => {
  return mq({
    marginTop: "1em",
    display: "flex",
    alignSelf: "center",
    justifySelf: "center",
    flexDirection: ["column", "row"],
    alignItems: "space-between",
    columnGap: "80px",
    flexWrap: "wrap",
  });
});

const quotes = [
  {
    author: "Neil N.",
    quote:
      "Affordable Flooring did an excellent job installing laminate flooring in our condo in Bonita Springs, FL. They were fast and efficient, very personable. No problems whatsoever. We would definitely hire them again and recommend to friends and family! And pricing was great, too.",
  },
  {
    author: "Timothy H.",
    quote: "Did the project timely and did a good job.",
  },
  {
    author: "Homeowner",
    quote:
      "I really like this young man. I wasn't there when he installed. I felt like I could trust him. He sent me pictures and it looks great. My friend has seen the finished work and says it looks great.",
  },
];

function QuoteItem({ quote, author }) {
  return <Quote quote={quote} author={author} />;
}

const IndexPage = () => {
  const Quotes = quotes.map((quote) => (
    <QuoteItem key={quote.id} quote={quote.quote} author={quote.author} />
  ));
  return (
    <Layout>
      <Section padding="0" height={["200px", "400px", "400px", "400px"]}>
        <OverlayLeft />
        <OverlayRight>
          <HeadingPrimary>
            MAKING IT COUNT. <br /> ​ON BUDGET. <br /> ON TIME.
          </HeadingPrimary>
        </OverlayRight>
        <Carousel
          sx={{
            maxWidth: 2048,
            height: "inherit",
            "@media (max-width: 768px)": {
              height: "inherit",
            },
            backgroundColor: "#2b3779",
          }}
          mx="auto"
          withIndicators
          slideSize="100%"
          slideGap="sm"
          background="black"
          controlsOffset="md"
          controlSize={30}
          loop
          dragFree
        >
          <Carousel.Slide style={{ height: "inherit" }}>
            <StaticImage
              src="../images/slideshow/kitchen.jpg"
              alt="Kitchen with floor"
              placeholder="blurred"
              style={{ height: "inherit" }}
              transformOptions={{ fit: "fill" }}
            />
          </Carousel.Slide>
          <Carousel.Slide style={{ height: "inherit" }}>
            <StaticImage
              src="../images/slideshow/lanai-focused.jpg"
              alt="lanai with wood flooring"
              placeholder="blurred"
              style={{ height: "inherit" }}
              transformOptions={{ fit: "fill" }}
            />
          </Carousel.Slide>
          <Carousel.Slide style={{ height: "inherit" }}>
            <StaticImage
              src="../images/slideshow/living-room-focused.png"
              alt="living room wood floor"
              placeholder="blurred"
              style={{ height: "inherit" }}
              transformOptions={{ fit: "cover" }}
            />
          </Carousel.Slide>
        </Carousel>
      </Section>

      <Section>
        <SectionContainer>
          <Heading as="h2" style={headingMedium}>
            Welcome to Affordable Flooring
          </Heading>
          <p style={paragraph}>
            Affordable Flooring is an independent, locally owned and operated
            family business. Nothing is more important to us than the
            satisfaction of our customers and the integrity of our work. We
            place the highest priority on your peace of mind during the
            installation process; everything we do, we do ourselves. As the
            owner-operator, I personally visit every client to access your
            needs, provide assistance in choosing the correct product, and
            scheduling the project in a timely manner. As an installer, I take
            extra care to get every detail correct and go the extra mile to
            ensure that your completed project looks just right.
          </p>
        </SectionContainer>
      </Section>

      <Section backgroundColor="#3f51b5" color="white">
        <SectionContainer>
          <Heading as="h2" style={headingMedium} color="white">
            Flooring Services
          </Heading>
          <p style={paragraph}>
            We may not be the largest or flashiest company in Southwest Florida,
            but we have built a reputation for exceptional service and quality
            workmanship. We specialize in the installation of hardwood,
            laminate, and vinyl flooring. From our initial consultation to the
            completion of the project, we provide outstanding service and
            attention to detail that many of our competitors lack.
          </p>
          <FlooringTypes>
            <div>
              <StaticImage
                src="../images/homepage/hardwood@3x.png"
                alt="hardwood flooring icon"
                height={250}
                style={FlooringIcon}
                placeholder="blurred"
              />
              <span style={FlooringIconTitle}>Hardwood</span>
            </div>
            <div>
              <StaticImage
                src="../images/homepage/laminate-icon@3x.png"
                alt="laminate flooring icon"
                height={250}
                style={FlooringIcon}
                placeholder="blurred"
              />

              <span style={FlooringIconTitle}>Laminate</span>
            </div>
            <div>
              <StaticImage
                src="../images/homepage/vinyl-plank-icon@3x.png"
                alt="vinyl plank icon"
                height={250}
                style={FlooringIcon}
                placeholder="blurred"
              />
              <span style={FlooringIconTitle}>Vinyl Plank</span>
            </div>
          </FlooringTypes>
        </SectionContainer>
      </Section>

      <Section backgroundColor="#f1f1f1">
        <SectionContainer>
          <Heading as="h2" style={headingMedium}>
            Product Support
          </Heading>
          <p style={paragraph}>
            Our new store offers a wide selection of flooring products featuring
            a full line of hardwood, laminate, and luxury vinyl options. We
            still offer full service installation on all products we sell as
            well as products purchased elsewhere. Stop by and see us today, or
            give us a call, and we can bring samples to you.
          </p>
        </SectionContainer>
      </Section>
      <Section>
        <SectionContainer>
          <Heading style={headingMedium}>
            Free Estimates and Consultation
          </Heading>
          <p style={paragraph}>
            When your flooring is outdated, worn, or damaged, it is time for a
            new look. This is the time to give us a call for a free estimate and
            consultation. As the owner of Affordable Flooring, I personally
            visit every customer to measure your house and provide a quote for
            our installation services. I will also help you choose the best
            product to meet your needs and budget.
          </p>
          <p style={paragraph}>
            Affordable Flooring makes getting beautiful new floors easy. If you
            want to improve the appearance of your home, give us a call and we
            will get back to you within one business day to schedule a free
            estimate and consultation. Take a few minutes and browse our website
            to learn more about our company and the services we offer.
          </p>
        </SectionContainer>
      </Section>
      <Section backgroundColor="#f1f1f1">
        <SectionContainer>
          <Heading as="h2" style={headingMedium}>
            Hear What Our Clients Had to Say
          </Heading>
          <ReviewContainer>
            <BirdEyeRectWidget />
            <MiddleReview>
              <BirdEyeRotatingWidget />
            </MiddleReview>
            <BirdEyeReviewGrid />
          </ReviewContainer>
        </SectionContainer>
      </Section>
      <Section backgroundColor="#3f51b5" color="white">
        <SectionContainer>
          <Heading as="h2" style={headingMedium} color="white">
            Awards
          </Heading>
          <StaticImage
            src="../images/homepage/angieslist.png"
            alt="hardwood flooring icon"
            width={300}
            style={AngiesList}
            placeholder="blurred"
          />
        </SectionContainer>
      </Section>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => (
  <title>Affordable Flooring of SWFL || Home Page</title>
);
