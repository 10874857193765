import * as React from "react";
import styled from "@emotion/styled";
import facepaint from "facepaint";

const breakpoints = [576, 768, 992, 1200];

const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledQuote = styled.p(({ color, align, style }) => {
  return mq({
    fontFamily: "Open Sans,Helvetica Neue,Arial,Helvetica,Verdana,sans-serif",
    fontSize: [18],
    paddingTop: "2em",
    fontWeight: "500",
    color: color | "#3f51b5",
    textAlign: 'left',
  });
});

const Author = styled.span(({ color, align, style }) => {
  return mq({
    fontFamily: "Open Sans,Helvetica Neue,Arial,Helvetica,Verdana,sans-serif",
    fontSize: [16],
    paddingTop: "2em",
    fontWeight: "800",
    color: color | "#3f51b5",
    textAlign: 'right',
  });
});

const Container = styled.div(({ color, align, style }) => {
  return mq({
    display: "flex",
    flexDirection: "column",
    alignItems: 'space-between',
    columnGap: 20,
    width: ['100%', '100%', '100%', '25%'],
    maxWidth: '800px'
  });
});

export function Quote({ quote, author }) {
  return (
    <Container>
      <StyledQuote withBorder shadow="sm" radius="md">
        {quote}
      </StyledQuote>
      <Author>{author}</Author>
    </Container>
  );
}
